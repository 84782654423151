<template>
    <v-container>
        <base-material-card icon="mdi-factory" title="Metas" class="px-5 py-3">
            <v-row>
                <v-col cols="12">
                    <v-btn color="primary" @click="create">Nuevo</v-btn>
                </v-col>
                <v-col cols="6">
                    <v-menu v-model="menu_fecha_inicio" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="pagination.fecha_inicial" label="Fecha inicial" readonly v-on="on" :error-messages="errors.fecha_inicial"></v-text-field>
                        </template>
                        <v-date-picker v-model="pagination.fecha_inicial" @input="menu_fecha_inicio = false; load()" locale="es-MX"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="6">
                    <v-menu v-model="menu_fecha_final" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="pagination.fecha_final" label="Fecha final" readonly v-on="on" :error-messages="errors.fecha_final"></v-text-field>
                        </template>
                        <v-date-picker v-model="pagination.fecha_final" @input="menu_fecha_final = false; load()" locale="es-MX"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="6">
                    <v-select v-model="pagination.cliente_id" @change="loadSucursales(); load()" :items="clientes" label="Cliente" item-value="id" item-text="razon_social" :error-messages="errors.cliente_id"></v-select>
                </v-col>
                <v-col cols="6">
                    <v-select v-model="pagination.sucursal_id" @change="load()" :items="sucursales" label="Sucursal" item-value="id" item-text="nombre" :error-messages="errors.sucursal_id"></v-select>
                </v-col>
            </v-row>
        </base-material-card>
        <v-card>
            <v-card-text>
                <v-data-table :headers="headers" :items="metas" hide-default-footer disable-pagination>
                    <template v-slot:body="{items}">
                        <tr v-for="item in items" :class="metaColor(item)">
                            <td class="font-weight-regular">{{item.sucursal.cliente.razon_social}}</td>
                            <td class="font-weight-regular">{{item.sucursal.nombre}}</td>
                            <td class="font-weight-regular">{{item.mes_string}}</td>
                            <td class="font-weight-regular">{{formatoMoneda(item.cantidad)}}</td>
                            <td class="font-weight-regular">{{formatoMoneda(item.vendido)}}</td>
                            <td class="font-weight-regular">{{formatoPorcentaje(item.porcentaje / 100)}}</td>
                            <td class="font-weight-regular">{{formatoMoneda(item.diario )}}</td>
                            <td class="font-weight-regular">{{formatoMoneda(item.tendencia )}} / {{formatoPorcentaje(item.tendencia_porcentaje / 100)}}</td>
                            <td>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn fab icon x-small v-on="on" @click="edit(item.id)">
                                            <v-icon>far fa-edit</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Editar</span>
                                </v-tooltip>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-meta-form :metaId="meta_id" :show="showForm" @close="showForm = false; load()"></v-meta-form>
    </v-container>
</template>
<script>
    export default {
        components: {
            'v-meta-form': () => import('./Form')
        },
        data: () => ({
            errors: {},
            headers: [
                { text: 'Cliente', sortable: false },
                { text: 'Sucursal', sortable: false },
                { text: 'Mes', sortable: false },
                { text: 'Meta', sortable: false },
                { text: 'Vendido', sortable: false },
                { text: 'Porcentaje', sortable: false },
                { text: 'Promedio diario', sortable: false },
                { text: 'Tendencia', sortable: false },
                { text: 'Acciones', value: 'accciones', sortable: false },
            ],
            clientes: [],
            sucursales: [],
            metas: [],
            pagination: {
                perPage: 10,
                lastPage: 1,
                currentPage: 1
            },
            showForm: false,
            menu_fecha_inicio: false,
            menu_fecha_final: false,
            meta_id: null
        }),
        mounted() {
            this.setActualMonth()
            this.loadClientes()
            this.load()
        },
        methods: {
            loadClientes() {
                this.$http.get('/clientes/all').then(response => {
                    this.clientes = response.data
                })
            },
            loadSucursales() {
                this.$http.get(`/clientes/${this.pagination.cliente_id}/sucursales`).then(response => {
                    this.sucursales = response.data
                })
            },
            create() {
                this.meta_id = null

                this.showForm = true
            },
            edit(id) {
                this.meta_id = id

                this.showForm = true
            },
            load() {
                this.$http.get(`/metas/`, {
                    params: this.pagination
                }).then(response => {
                    this.metas = response.data
                }).catch(error => {
                    switch (error.response.status) {
                        case 422:
                            this.errors = error.response.data.errors
                            break;

                        default:
                            break;
                    }
                })
            },
            setActualMonth() {
                this.pagination.fecha_inicial = moment().startOf('month').format('YYYY-MM-DD')
                this.pagination.fecha_final = moment().endOf('month').format('YYYY-MM-DD')
            },
            metaColor(meta) {
                if (meta.tendencia_porcentaje < 50) {
                    return 'red--text'
                }
                
                if (meta.tendencia_porcentaje < 70) {
                    return 'orange--text'
                }
                
                if (meta.tendencia_porcentaje < 90) {
                    return 'yellow--text'
                }
                
                if (meta.tendencia_porcentaje < 100) {
                    return 'green--text'
                }

                return 'blue--text'
            }
        }
    }
</script>